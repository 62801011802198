<template>
  <div class="page-cu-container">
    <div class="page-cu-main">
      <div>
        <div ref="searchbox" style="margin-top: 20px">
          <a-form layout="inline">
            <a-form-item>
              <a-input
                v-model="adminPage.key"
                placeholder="请输入关键字"
              ></a-input>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 200px"
                v-model="adminPage.subject_category_id"
                placeholder="请选择题目分类"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="(item, index) in departmentTree"
                  :key="index"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 200px"
                v-model="adminPage.type"
                placeholder="请选择题型"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="1">判断题</a-select-option>
                <a-select-option value="2">单选题</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 200px"
                v-model="adminPage.state"
                placeholder="请选择状态"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="1">有效</a-select-option>
                <a-select-option value="2">无效</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="searchList" icon="search"
                >搜索</a-button
              >
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="openAdminAdd"
                ><a-icon type="plus-circle" /> 添加题目</a-button
              >
            </a-form-item>
<!--            <a-form-item>-->
<!--              <a-button-->
<!--                :icon="getExicon.icon"-->
<!--                :disabled="getExicon.ed"-->
<!--                @click="$exportFun('/export/subject', adminPage)"-->
<!--                >导出</a-button-->
<!--              >-->
<!--            </a-form-item>-->
          </a-form>
        </div>
        <a-table
          style="margin-top: 20px"
          :scroll="{ y: tableHeigt }"
          :columns="adminColumns"
          :data-source="adminList"
          :pagination="false"
          :loading="loading"
          bordered
          rowKey="id"
        >
          <template slot="state" slot-scope="text, record">
            <a-tag v-if="text == '1'" color="green">启用</a-tag>
            <a-tag v-else color="red">禁用</a-tag>
          </template>
          <template slot="type" slot-scope="text, record">
            <a-tag v-if="text == '1'" color="blue">判断题</a-tag>
            <a-tag v-else color="cyan">单选题</a-tag>
          </template>

          <template slot="operation" slot-scope="text, record">
            <a-button
              type="link"
              size="small"
              icon="edit"
              @click="() => openEditDialog(record['id'])"
              >编辑</a-button
            >
            <!-- <a-button
              type="link"
              size="small"
              icon="delete"
              @click="() => delOpenDialog(record['id'])"
              >删除</a-button
            >
            <a-button
              type="link"
              size="small"
              icon="minus-circle"
              color="blue"
              v-if="record['state'] == '1'"
              @click="() => enabledState(record['id'], 2, '你确定要禁用吗？')"
              >禁用</a-button
            >
            <a-button
              type="link"
              size="small"
              icon="check-circle"
              v-if="record['state'] == '2'"
              @click="() => enabledState(record['id'], 1, '你确定要启用吗？')"
              >启用</a-button
            > -->
          </template>
        </a-table>
        <div class="page-cu-pagination">
          <a-pagination
            :show-total="(total, range) => `总数：${total} 条`"
            :page-size.sync="adminPage.pageSize"
            :total="adminPage.totalRow"
            v-model="adminPage.start"
            @change="changePage"
          />
        </div>
      </div>
    </div>

    <a-modal
      :maskClosable="false"
      destroyOnClose
      @ok="formAdminDataSubmit"
      okText="确定"
      cancelText="取消"
      width="600px"
      v-model="dialogAdmin"
      title="题目管理"
    >
      <div class="dialog_box">
        <div>
          <a-form-model
            ref="formData"
            :rules="formAdminRules"
            :model="formAdmin"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
          >
            <a-form-model-item label="标题" prop="title">
              <a-input placeholder="请输入标题" v-model="formAdmin.title" />
            </a-form-model-item>
            <a-form-model-item label="题目分类" prop="subject_category_id">
              <a-select
                v-model="formAdmin.subject_category_id"
                placeholder="请选择题目分类"
              >
                <a-select-option
                  v-for="(item, index) in departmentTree"
                  :key="index"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="题型" prop="type">
              <a-radio-group v-model="formAdmin.type">
                <a-radio value="1">判断题</a-radio>
                <a-radio value="2">单选题</a-radio>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item label="分数" prop="score">
              <a-input
                placeholder="请输入题目分数"
                disabled
                v-model="formAdmin.score"
              />
            </a-form-model-item>
            <div v-if="formAdmin.type == 2">
              <a-form-model-item label="答案A" prop="a_answer">
                <a-input
                  placeholder=""
                  v-model="formAdmin.a_answer"
                  type="textarea"
                  row="2"
                />
              </a-form-model-item>
              <a-form-model-item label="答案B" prop="b_answer">
                <a-input
                  placeholder=""
                  v-model="formAdmin.b_answer"
                  type="textarea"
                  row="2"
                />
              </a-form-model-item>
              <a-form-model-item label="答案C" prop="c_answer">
                <a-input
                  placeholder=""
                  v-model="formAdmin.c_answer"
                  type="textarea"
                  row="2"
                />
              </a-form-model-item>
              <a-form-model-item label="答案D" prop="d_answer">
                <a-input
                  placeholder=""
                  v-model="formAdmin.d_answer"
                  type="textarea"
                  row="2"
                />
              </a-form-model-item>
              <a-form-model-item label="答案E" prop="e_answer">
                <a-input
                  placeholder=""
                  v-model="formAdmin.e_answer"
                  type="textarea"
                  row="2"
                />
              </a-form-model-item>
              <a-form-model-item label="答案B" prop="f_answer">
                <a-input
                  placeholder=""
                  v-model="formAdmin.f_answer"
                  type="textarea"
                  row="2"
                />
              </a-form-model-item>
            </div>
            <div v-else>
              <a-form-model-item label="答案A" prop="a_answer">
                <a-input
                  placeholder=""
                  v-model="formAdmin.a_answer"
                  type="textarea"
                  row="2"
                />
              </a-form-model-item>
              <a-form-model-item label="答案B" prop="b_answer">
                <a-input
                  placeholder=""
                  v-model="formAdmin.b_answer"
                  type="textarea"
                  row="2"
                />
              </a-form-model-item>
            </div>
            <a-form-model-item label="正确答案" prop="right_answer">
              <a-select style="" v-model="formAdmin.right_answer">
                <!-- <a-select-option
                  v-for="(item, index) in rightData"
                  :key="index"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                > -->
                <a-select-option value="A"> A </a-select-option>
                <a-select-option value="B"> B </a-select-option>
                <a-select-option value="C" v-if="formAdmin.type == 2">
                  C
                </a-select-option>
                <a-select-option value="D" v-if="formAdmin.type == 2">
                  D
                </a-select-option>
                <a-select-option value="E" v-if="formAdmin.type == 2">
                  E
                </a-select-option>
                <a-select-option value="F" v-if="formAdmin.type == 2">
                  F
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="排序" prop="sort">
              <a-input placeholder="" v-model="formAdmin.sort" />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
import ATextarea from "ant-design-vue/es/input/TextArea";

export default {
  components: { ATextarea },
  data() {
    return {
      tableHeigt: 0,
      loading: true,
      viewImgData: "", // 封面图预览
      page: {
        start: 1,
        limit: 20,
        key: "",
        totalRow: 0,
      },
      roleList: [],
      departmentTree: [],
      selectCurrentNodeId: "",
      adminList: [],
      adminPage: {
        start: 1,
        limit: 20,
        pageSize: 10,
        totalRow: "",
        key: "",
        type: undefined,
        state: undefined,
        subject_category_id: undefined,
        totalRow: 0,
      },
      rightData: [
        {
          id: "A",
          name: "A",
        },
        {
          id: "B",
          name: "B",
        },
        {
          id: "C",
          name: "C",
        },
        {
          id: "D",
          name: "D",
        },
        {
          id: "E",
          name: "E",
        },
        {
          id: "F",
          name: "F",
        },
      ],
      formAdmin: {
        title: "",
        type: "1",
        subject_category_id: "",
        score: "5",
        a_answer: "",
        b_answer: "",
        c_answer: "",
        d_answer: "",
        e_answer: "",
        f_answer: "",
        right_answer: "",
        sort: "",
      },
      formAdminRules: {
        title: [{ required: true, message: "请输入标题", trigger: "change" }],
        a_answer: [
          { required: true, message: "请输入答案", trigger: "change" },
        ],
        subject_category_id: [
          { required: true, message: "请选择题目类型", trigger: "change" },
        ],
        b_answer: [
          { required: true, message: "请输入答案", trigger: "change" },
        ],
        right_answer: [
          { required: true, message: "请选择正确答案", trigger: "change" },
        ],
        sort_num: [
          { required: true, message: "请输入排序", trigger: "change" },
        ],
      },
      dialogAdmin: false,
      adminColumns: [
        {
          title: "标题",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "题目类型",
          dataIndex: "subjectCategoryName",
          key: "subjectCategoryName",
        },
        {
          title: "题型",
          dataIndex: "type",
          key: "type",
          align: "center",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "正确答案",
          dataIndex: "right_answer",
          key: "right_answer",
          width: 100,
          align: "center",
        },

        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
          width: 170,
        },
        {
          title: "状态",
          dataIndex: "state",
          key: "state",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "state" },
        },
        {
          title: "操作",
          fixed: "right",
          dataIndex: "operation",
          key: "operation",
          width: 240,
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      dialogAdminPwd: false,

      dialogDept: false,
      formDept: {
        pid: "",
        name: "",
        sort: "",
        represent: "",
      },
      formDeptRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序数", trigger: "blur" }],
      },
      topAdminList: [],
    };
  },
  mounted() {
    this.getTreeList();
    this.getAdminList();
    this.$nextTick(function () {
      this.tableHeigt =
        document.body.clientHeight - this.$refs.searchbox.offsetHeight - 210;
    });
  },
  computed: {
    getExicon() {
      return this.$store.state.EXICON;
    },
  },
  methods: {
    // 搜索
    searchList() {
      this.adminPage.start = 1;
      this.getAdminList();
    },
    // TODO:题目分类下拉框
    roleHandleChange(value) {
      this.formAdmin.role_ids = value.toString();
      console.log(this.formAdmin.role_ids);
    },
    async getTreeList() {
      const res = await Api.GetTreeList();
      this.departmentTree = res.treeList;
    },
    /**
     * 选中树节点
     */
    selectTree(key, event) {
      console.log(event.node.dataRef.id);
      this.selectCurrentNodeId = event.node.dataRef.id; // 添加分类的时候
      this.adminPage.subject_category_id = event.node.dataRef.id; // 添加题目的时候赋值分类ID
      this.getAdminList();
    },
    /**
     * 重置分类树树form
     */
    resetFormDept() {
      delete this.formDept["id"];
      this.formDept.name = "";
      for (let key in this.formDept) {
        this.formDept[key] = "";
      }
    },
    /**
     * 打开分类树添加的弹框
     */
    openDeptAdd() {
      this.resetFormDept();
      this.dialogDept = true;
      if (this.selectCurrentNodeId == "") {
        this.formDept.pid = 0;
      } else {
        this.formDept.pid = this.selectCurrentNodeId;
      }
    },
    async openDeptEdit() {
      if (this.selectCurrentNodeId == "" || this.selectCurrentNodeId == "0") {
        this.$message.error("请选择要编辑的分类");
      } else {
        this.resetFormDept();
        let res = await Api.DetailDepartment({ id: this.selectCurrentNodeId });
        if (res && res["code"] == "0") {
          this.formDept = Object.assign({}, res.target);
          this.dialogDept = true;
        }
      }
    },
    /**
     * 分类树添加
     */
    addDeptId(arr, id, obj) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          if (item.children) {
            item.children.push(obj);
          } else {
            item["children"] = [obj];
          }
          break;
        } else if (item.children && item.children.length > 0) {
          this.addDeptId(item.children, id, obj);
        }
      }
    },
    /**
     * 分类树编辑
     */
    editDeptId(arr, id, obj) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          item.name = obj;
          break;
        } else if (item.children && item.children.length > 0) {
          this.editDeptId(item.children, id, obj);
        }
      }
    },
    /**
     * 添加分类树
     */
    deptDataSubmit() {
      this.$refs["formDept"].validate(async (valid) => {
        if (valid) {
          let res = "";
          if (this.formDept && this.formDept["id"]) {
            // TODO:区分是新增还是编辑
            res = await Api.UpdateDepartment(this.formDept);
          } else {
            res = await Api.SaveDepartment(this.formDept);
          }
          if (res && res["code"] == "0") {
            // if (this.formDept && this.formDept["id"]) {
            //   this.editDeptId(
            //     this.departmentTree,
            //     this.selectCurrentNodeId,
            //     this.formDept.name
            //   );
            // } else {
            //   let temp = res["obj"];
            //   this.addDeptId(
            //     this.departmentTree,
            //     this.selectCurrentNodeId,
            //     temp
            //   );
            // }
            this.getTreeList();
            this.resetFormDept();
            this.dialogDept = false;
            this.$message.success(res.message);
            this.addDeptId();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 打开分类树删除弹框
     */
    openDeptDelete() {
      if (this.selectCurrentNodeId == "") {
        this.$message.error("不能删除或没有选择节点");
        return;
      }
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        async onOk() {
          let res = await Api.DeleteDepartment({
            id: that.selectCurrentNodeId,
          });
          if (res && res["code"] == "0") {
            that.deleteDeptId(that.departmentTree, that.selectCurrentNodeId);
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    /**
     * 分类树删除
     */
    deleteDeptId(arr, id) {
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item.id === id) {
          arr = arr.splice(i, 1);
          break;
        } else if (item.children && item.children.length > 0) {
          this.deleteDeptId(item.children, id);
        }
      }
    },
    /**
     * 获取题目分类下拉列表
     */
    async getRoleList() {
      const res = await Api.GetRoleList();
      this.roleList = res.list;
    },

    /**
     * 获取题目列表
     */
    async getAdminList() {
      this.loading = true;
      const res = await Api.GetAdminList({ ...this.adminPage });
      this.adminList = res.page.list;
      this.adminPage.totalRow = res.page.totalRow;
      this.loading = false;
    },
     changePage(currentPage) {
      this.adminPage.start = currentPage;
      this.getAdminList();
    },
   
    /**
     * 重置题目form
     */
    resetFormAdmin() {
      for (let key in this.formAdmin) {
        this.formAdmin[key] = "";
      }
      this.formAdmin.score = 5;
    },
    /**
     * 打开题目添加窗口
     */
    openAdminAdd() {
      this.resetFormAdmin();
      this.formAdmin.type = "2";
      this.dialogAdmin = true;
    },
    /**
     * 打开题目编辑窗口
     */
    async openEditDialog(id) {
      this.resetFormAdmin();
      let res = await Api.AdminDetail({ id: id });
      if (res && res["code"] == "0") {
        this.formAdmin = Object.assign({}, res.target);
        this.dialogAdmin = true;
      }
    },
    /**
     * 题目提交
     */
    formAdminDataSubmit() {
      this.$refs["formData"].validate(async (valid) => {
        if (valid) {
          let res = "";
          if (this.formAdmin && this.formAdmin["id"]) {
            res = await Api.AdminUpdate(this.formAdmin);
          } else {
            res = await Api.AdminSave(this.formAdmin);
          }
          if (res && res["code"] == "0") {
            this.resetFormAdmin();
            this.dialogAdmin = false;
            this.getAdminList();
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 删除题目
     */
    delOpenDialog(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        async onOk() {
          let res = await Api.AdminDelete({ id: id });
          if (res && res["code"] == "0") {
            that.getAdminList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },

    // 启用 / 禁用
    enabledState(id, state, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            id: id,
            state: state,
          };
          let res = await Api.DisabledEnabled(params);
          if (res && res["code"] == "0") {
            that.getAdminList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    /**
     * 题目封面上传完成的事件
     * @author wwq
     * */
    changeImgUrl(url) {
      this.formAdmin.img_url = url;
    },
    /**
     * 题目上传完成的事件
     * @author wwq
     * */
    changeVideoUrl(url) {
      this.formAdmin.video_url = url;
    },
  },
};
</script>

<style>
/*.ant-layout-content .main_content {*/
/*display: flex;*/
/*flex-direction: column;*/
/*height: 100%;*/
/*}*/
</style>

