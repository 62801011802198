
import request from '@/utils/request';

/**
 * 获取分类树结构
 */
export function GetTreeList() {
    return request({
        url: '/admin/subjectCategory/getTreeList',
        method: 'GET',
    });
}

/**
 * 创建分类
 */
export function SaveDepartment(data) {
    return request({
        url: '/admin/subjectCategory/save',
        method: 'POST',
        data
    });
}

/**
 * 修改分类
 */
export function UpdateDepartment(data) {
    return request({
        url: '/admin/subjectCategory/update',
        method: 'POST',
        data
    });
}

/**
 * 删除分类
 */
export function DeleteDepartment(params) {
    return request({
        url: '/admin/subjectCategory/delete',
        method: 'GET',
        params
    });
}

/**
 * 分类详情
 */
export function DetailDepartment(params) {
    return request({
        url: '/admin/subjectCategory/detail',
        method: 'GET',
        params
    });
}

/**
 * 题目下拉列表
 */
export function GetRoleList(params) {
    return request({
        url: '/admin/subject/getBusVideoCategoryJson',
        method: 'GET',
        params
    });
}

/**
 * 题目列表
 */
export function GetAdminList(params) {
    return request({
        url: '/admin/subject/page',
        method: 'GET',
        params
    });
}

/**
 * 添加题目
 */
export function AdminSave(data) {
    return request({
        url: '/admin/subject/save',
        method: 'POST',
        data
    });
}

/**
 * 编辑题目
 */
export function AdminUpdate(data) {
    return request({
        url: '/admin/subject/update',
        method: 'POST',
        data
    });
}

/**
 * 详情 题目
 */
export function AdminDetail(params) {
    return request({
        url: '/admin/subject/detail',
        method: 'GET',
        params
    });
}

/**
 * 删除题目
 */
export function AdminDelete(params) {
    return request({
        url: '/admin/subject/delete',
        method: 'GET',
        params
    });
}


/**
 * 启用、禁用题目
 */
export function DisabledEnabled(params) {
    return request({
        url: '/admin/subject/changeIndex',
        method: 'GET',
        params
    });
}





